import './index.css';
import { setup } from '@contentful/ecommerce-app-base';
import { Button, EntityList } from '@contentful/f36-components';
import { render } from 'react-dom';
import { useEffect, useState } from 'react';

import ProductSearch from './ProductSearch/ProductSearch';
import { CROP_BANNER, CROP_LIST } from './constants/content-types';

import { init as initContentfulApp } from '@contentful/app-sdk';
import { channelList } from './utils/channel-list.ts';

const environmentURLMap = {
  'master': 'https://webshop-prod-live-backend.rijkzwaan.com',
  'acceptance': 'https://webshop-pre-live-backend.rijkzwaan.com',
  'development': 'https://webshop-int-live-backend.rijkzwaan.com'
};
let entryCountryTagID;
let host;

initContentfulApp((sdk) => {
  if (sdk.entry && entryCountryTagID === undefined && host === undefined) {
    entryCountryTagID = sdk.entry.getMetadata().tags.map((tag) => tag.sys.id);
    host = environmentURLMap[sdk.ids.environment];
  }
});

setup({
  makeCTA: () => 'Select a product',
  name: 'Rijk Zwaan Product Picker',
  logo: 'https://logosandtypes.com/wp-content/uploads/2020/07/intershop.svg',
  color: '#036FE3',
  description: 'This is an Application to select a product from ICM.',
  fetchProductPreviews,
  renderDialog,
  openDialog,
  isDisabled: () => false,
});

function DialogLocation({ sdk }) {
  const [limit, setLimit] = useState(6);
  const [searchTerm, setSearchTerm] = useState('Celery');
  const [site, setSite] = useState('rijkzwaan-australia-Site/rest;loc=en_AU;cur=AUD');
  const isCrop = sdk.ids.contentType === CROP_LIST || sdk.ids.contentType === CROP_BANNER;
  const host = environmentURLMap[sdk.ids.environment];

  let skuList = sdk.parameters.invocation._currentValue;
  const [products, setProducts] = useState();
  useEffect(async () => {
    const fetchProducts = async () => {
      const response = await fetch(
        isCrop ? 
        `${host}/INTERSHOP/rest/WFS/${site}/categories?imageView=front&view=tree&limit=${limit}&omitHasOnlineProducts=true` : 
        `${host}/INTERSHOP/rest/WFS/${site}/products?searchTerm=${searchTerm}&amount=${limit}&offset=0&attrs=sku,availability,manufacturer,image,minOrderQuantity,maxOrderQuantity,stepOrderQuantity,inStock,promotions,packingUnit,mastered,productMaster,productMasterSKU,roundedAverageRating,retailSet,defaultCategory&attributeGroup=PRODUCT_LABEL_ATTRIBUTES&returnSortKeys=true&isMastered=0`
      );
      return response.json();
    };

    fetchProducts().then(prods => {
      setProducts(prods);
      skuList.forEach(sku => document.querySelector('#' + sku.split('@')[0])?.classList.add('id-selected'));
    });
  }, [isCrop, host, limit, searchTerm, site]);

  if (products === undefined) {
    return <div>Please wait</div>;
  }

  function updateValues(values) {
    setLimit(values[0]);
    setSearchTerm(values[1]);
    setSite(values[2]);
  }

  function updateSkuList(id) {
    if (skuList.find(sku => sku === id)) {
      skuList = skuList.filter(sku => sku !== id);
      document.querySelector('#' + id.split('@')[0]).classList.remove('id-selected');
    } else {
      skuList.push(id);
      document.querySelector('#' + id.split('@')[0]).classList.add('id-selected');
    }
  }

  return (
    <div>
      <ProductSearch isCrop={isCrop} onSearch={updateValues} />
      {isCrop ? 
        <EntityList>
          {products.elements[0].subCategories.map((category) => (
            <EntityList.Item
              id = {category.id}
              key = {category.id}
              title = {category.name}
              description = {category.id}
              thumbnailUrl= {category.images?.[0]?.effectiveUrl}
              onClick={() => updateSkuList(category.categoryRef)}
            />
          ))}
        </EntityList>
      : <EntityList>
        {products.elements.map((product) => (
          <EntityList.Item
            id = {product.attributes.find(attribute => attribute.name === 'sku' ).value}
            key = {product.attributes.find(attribute => attribute.name === 'sku' ).value}
            title = {product.title}
            description = {product.attributes.find(attribute => attribute.name === 'sku' ).value}
            thumbnailUrl= {product.attributes.find(attribute => attribute.name === 'image' )?.value}
            onClick={() => updateSkuList(product.attributes.find(attribute => attribute.name === 'sku' )?.value)}
          />
        ))}
      </EntityList>
      }
      <Button variant="primary" onClick={() => sdk.close(skuList)} className="submit-product">
        Submit products
      </Button>
    </div>
  );
}

async function fetchProductPreviews(skus) {
  if (!skus.length) {
    return [];
  }

  const sites = entryCountryTagID.map((tag) => {
    return channelList[tag] === undefined ? '' : channelList[tag].icmChannel;
  });
  const fetchProducts = async (sku, isCrop, sites) => {
    for (const site of sites) {
      if (site === '') continue;

      const response = await fetch(
        isCrop ?
        `${host}/INTERSHOP/rest/WFS/${site}/categories/${sku}` :
        `${host}/INTERSHOP/rest/WFS/${site}/products/${sku}?attrs=images`
      );
      if (response.ok) return response.json();
    }
    return undefined;
  };

  return Promise.all(skus.map(async sku => {
    const isCrop = sku.includes('@');
    let productName = undefined;
    let productImage = undefined;

    const productInfo = await fetchProducts(sku, isCrop, sites);
    if (productInfo && productInfo.name) productName = isCrop ? sku.split('@')[0] : productInfo.name;
    if (productInfo && productInfo.images) productImage = isCrop ? productInfo.images[0].effectiveUrl : productInfo.images.find(img => img.typeID === 'M' )?.effectiveUrl;

    return {
      sku: sku,
      image: productImage,
      id: sku,
      name: productName,
    };
  }));
}

async function renderDialog(sdk) {
  render(<DialogLocation sdk={sdk} />, document.getElementById('root'));
  sdk.window.startAutoResizer();
}

async function openDialog(sdk, _currentValue, _config) {
  const skus = await sdk.dialogs.openCurrentApp({
    position: 'center',
    title: 'Rijk Zwaan Product Picker',
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEscapePress: true,
    width: 500,
    allowHeightOverflow: true,
    parameters: { _config, _currentValue },
  });

  return Array.isArray(skus) ? skus : [];
}
